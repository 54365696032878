import * as React from 'react'

import { StaticImage } from 'gatsby-plugin-image'

import Layout from '../../../templates/layout'
import Seo from '../../../helpers/seo'
import CTA from '../../../components/organisms/CTA'
import CasesRelational from '../../../components/organisms/CasesRelational'
import gringoJson from './gringo.json'
import Content from './content'

import { isMobile } from 'react-device-detect'

const Gringo = () => {
  const { relational } = gringoJson

  const Banner = () => {
    return (
      <section className="content-banner">
        {isMobile ? (
          <StaticImage
            src="../../../assets/images/cases-content/gringo-banner.webp"
            alt="Looz"
            placeholder="blurred"
            loading="eager"
            width={920}
            objectFit="cover"
            layout="fixed"
            objectPosition="center"
            quality={95}
            formats={['auto', 'webp', 'avif']}
          />
        ) : (
          <StaticImage
            src="../../../assets/images/cases-content/gringo-banner.webp"
            alt="Looz"
            placeholder="blurred"
            loading="eager"
            width={1920}
            objectFit="cover"
            layout="fixed"
            objectPosition="center"
            quality={95}
            formats={['auto', 'webp', 'avif']}
          />
        )}
      </section>
    )
  }

  return (
    <Layout page="Gringo">
      <Banner />
      <Content />
      <CTA />
      <CasesRelational relationalData={relational} itsRelationalCase />
    </Layout>
  )
}
export const Head = () => <Seo title="Gringo" />

export default Gringo
